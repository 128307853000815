import React, { createContext, useContext } from 'react';

const getEnvVar = (key) => {
    const value = window._env_ && window._env_[key] !== undefined
        ? window._env_[key]
        : process.env[key];

    if (value === undefined) {
        throw new Error(`La variable d'environnement ${key} n'est pas définie.`);
    }

    return value;
};

const ConfigContext = createContext(null);

export const ConfigProvider = ({ children }) => {
    const apiUrl = getEnvVar('REACT_APP_API_URL');
    const apiPort = getEnvVar('REACT_APP_API_PORT');
    const apiToken = getEnvVar('REACT_APP_API_TOKEN');
    const apiUploadDir = getEnvVar('REACT_APP_API_UPLOAD_DIR');

    const config = {
        apiUrl,
        apiPort,
        apiFullUrl: `${apiUrl}:${apiPort}`,
        apiToken,
        apiUploadDir
    };

    console.log("config env");
    console.log(config.apiFullUrl);
    console.log(window._env_);

    return (
        <ConfigContext.Provider value={config}>
            {children}
        </ConfigContext.Provider>
    );
};

export const useConfig = () => useContext(ConfigContext);
