import { useConfig } from '../ConfigContext';

const useApi = () => {
    const { apiFullUrl, apiToken } = useConfig();

    const fetchData = async (endpoint) => {
        const headers = { 'Authorization': `Bearer ${apiToken}` };
        const response = await fetch(`${apiFullUrl}${endpoint}`, { headers });
        console.log("apiFullUrl : " + apiFullUrl);
        console.log("endpoint : " + endpoint);

        if (!response.ok) {
            throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        return await response.json();
    };

    return { fetchData };
};

export default useApi;
